import {createSelector} from 'reselect'
import {$Keys} from 'utility-types'

import type {CollapsibleBlock} from '../actions/collapsibleBlockTypes'
import CollapsibleBlocks from '../actions/collapsibleBlockTypes'
import {isCloudImageManageable} from '../components/AgentsScreen/AgentsScreen.utils'
import {getExpandAll, getFocusLine, getLogFilter} from '../components/BuildLog/BuildLog.selectors'
import {
  OldChangePageTabNamesEnum,
  OldChangePageTabNamesEnumType,
} from '../components/common/ChangeDetailsTabs/ChangeDetailsTabs.types'
import {getCurrentPage, getPager} from '../components/common/Pager/Pager.selectors'
import {PagerGroup} from '../components/common/Pager/Pager.types'
import {getOffset} from '../components/common/Pager/Pager.utils'
import type {ClientId, State} from '../reducers/types'
import {getArtifactsKey} from '../reducers/types'
import {getAgentPreviewsArg, getAgentsArg} from '../rest/agents'
import type {FetchBranchesParams} from '../rest/branches'
import {getEndpointForBranches} from '../rest/branches'
import {BuildTypeProperties} from '../rest/buildTypes'
import {getBranchLocator} from '../rest/locators'
import type {StatusKey} from '../rest/schemata'
import {getStatusKey, NormalizedBuild} from '../rest/schemata'
import {getOverviewHref} from '../routes'
import {restApi, Build, User} from '../services/rest'
import type {
  ActiveEntityProps,
  ActiveEntityURLProps,
  AgentFilter,
  AgentId,
  AgentPoolId,
  AgentPoolsHashType,
  AgentPoolType,
  AgentPreviewsHashType,
  AgentRequestOptions,
  AgentTypeId,
  ArtifactExtensions,
  BranchType,
  BuildArtifactsSizeType,
  BuildArtifactsType,
  BuildId,
  BuildState,
  BuildStateType,
  BuildStats,
  BuildTriggeredBuildType,
  BuildTypeId,
  BuildTypePathItem,
  BuildTypeType,
  ChangeId,
  CommentInfo,
  CompatibleAgentType,
  DialogType,
  EntityDescription,
  EntityProjectsIdsList,
  FederationServerId,
  Fetchable,
  FileType,
  FullPath,
  Id,
  InexactEntityParameters,
  LocatorOptions,
  NodeType,
  NormalizedAgentType,
  NormalizedBranchWithBuilds,
  NormalizedBuildTriggeredType,
  NormalizedBuildType,
  NormalizedChangeType,
  NormalizedCloudImageType,
  NormalizedProjectType,
  NormalizedVcsRootInstanceType,
  Permission,
  ProjectId,
  ProjectOrBuildTypeNode,
  ProjectOrBuildTypeStatus,
  ProjectPathItem,
  ServerInfo,
  Sorting,
  StatusRequest,
  Tab,
  TabId,
  TabParamsKey,
  TagType,
  TestId,
  UrlExtension,
  UserId,
  VcsRootId,
  VcsRootInstanceId,
  VcsRootType,
  WebLinks,
} from '../types'
import {
  BuildPageTabNamesEnum,
  BuildTypePageTabNamesEnum,
  cloneNode,
  CurrentUserType,
  getBuildTypeStatusRequest,
  MainUIBuildTypeTabNamesEnum,
  ProjectPageTabNamesEnum,
  ROOT_PROJECT_ID,
  SakuraUIEnabledEnum,
  STAR_TAG,
  STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER,
  stateLocators,
  stringifyId,
  Template,
  toProjectId,
  WritableAgentPreviewsHashType,
} from '../types'
import {internalProps} from '../types/BS_types'
import {getBranchParam, stringifyBranch} from '../utils/branchNames'
import {emptyArray, emptyArrayFetchable, getEmptyHash} from '../utils/empty'
import {resolveWebEntityLink} from '../utils/entityLinks'
import escapeLocatorDimension from '../utils/escapeLocatorDimension'
import {getErrorIfReady} from '../utils/fetchable'
import {getPropertyFromList} from '../utils/getProperty'
import {notNull} from '../utils/guards'
import type {FullPathCacheItem} from '../utils/memoryCache'
import memoryCache from '../utils/memoryCache'
import type {KeyValue} from '../utils/object'
import {objectEntries, objectValues, WritableKeyValue} from '../utils/object'
import type {QueryParams} from '../utils/queryParams'
import {objectToQuery, queryToObject} from '../utils/queryParams'
import {getTabParamsKey} from '../utils/tabs'
import {parseURL, resolveRelative} from '../utils/url'
import type {UserProperty} from '../utils/userProperties'
import {UserProperties} from '../utils/userProperties'

import type {ProjectsTreeType} from './projectTrees'
// For some reason, calling a method on emptyArray makes type refinement work correctly
emptyArray.slice()
export const getBuildTypesHash: (arg0: State) => KeyValue<BuildTypeId, BuildTypeType> = state =>
  state.entities.buildTypes
export const getBuildType: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => BuildTypeType | null | undefined = (state, id) =>
  id != null ? getBuildTypesHash(state)[id] : null
export const getBuildTypeLinks: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => WebLinks | null | undefined = (state, id) => (id ? state.entities.buildTypeLinks[id] : null)
export const getProjectLinks: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => WebLinks | null | undefined = (state, id) => (id ? state.entities.projectLinks[id] : null)
export const getBuildTypeParameters: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => InexactEntityParameters | null | undefined = (state, id) =>
  id ? state.entities.buildTypeParameters[id] : null
export const getBuildTypePauseComment = (state: State, id: BuildTypeId) =>
  state.entities.buildTypePauseComment[id]?.pauseComment
const getProjectParameters: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => InexactEntityParameters | null | undefined = (state, id) =>
  id ? state.entities.projectParameters[id] : null
export const isBuildTypeLoaded: (arg0: State, arg1: BuildTypeId | null | undefined) => boolean = (
  state,
  id,
) =>
  id
    ? getBuildType(state, id) != null &&
      getBuildTypeLinks(state, id) != null &&
      getBuildTypeParameters(state, id) != null
    : false
export const getOverviewBuildTypesHash: (
  arg0: State,
) => KeyValue<BuildTypeId, BuildTypeType> = state => state.entities.overviewBuildTypes
export const getOwnerProjectId: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => ProjectId | null | undefined = (state, buildTypeId) =>
  getBuildType(state, buildTypeId)?.projectId
export const getProjectsHash: (arg0: State) => KeyValue<ProjectId, NormalizedProjectType> = state =>
  state.entities.projects
export const getProjectTemplates = (state: State): KeyValue<ProjectId, readonly Template[]> =>
  state.entities.projectTemplates
export const getProject: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => NormalizedProjectType | null | undefined = (state, id) =>
  id != null ? getProjectsHash(state)[id] : null

export const getTabsFetchable: (
  state: State,
  key: TabParamsKey,
) => Fetchable<ReadonlyArray<Tab>> = (state, key) => state.tabs[key] ?? emptyArrayFetchable

export const getTabs: (state: State, key: TabParamsKey) => ReadonlyArray<Tab> = (state, key) =>
  getTabsFetchable(state, key).data

export const getIsAllTabsLoading: (state: State, key: TabParamsKey) => boolean = (state, key) =>
  getTabsFetchable(state, key).loading

export const getIsAllTabsInited: (state: State, key: TabParamsKey) => boolean = (state, key) =>
  getTabsFetchable(state, key).inited

export const getIsAllTabsReady: (state: State, key: TabParamsKey) => boolean = (state, key) => {
  const tabsFetchable = getTabsFetchable(state, key)
  return !tabsFetchable.error && tabsFetchable.ready
}

export const isProjectLoaded: (arg0: State, arg1: ProjectId | null | undefined) => boolean = (
  state,
  id,
) =>
  id
    ? getProject(state, id) != null &&
      getProjectLinks(state, id) != null &&
      getProjectParameters(state, id) != null
    : false
export const isArchivedProject: (arg0: State, arg1: ProjectId | null | undefined) => boolean = (
  state,
  id,
) => {
  const project = getProject(state, id)
  return project ? project.archived : false
}
const getProjectArchivedSubprojectsIdsHash: (
  arg0: State,
) => KeyValue<ProjectId, EntityProjectsIdsList> = state =>
  state.entities.projectArchivedSubprojectsIds
export const getProjectArchivedSubprojectsIds: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => EntityProjectsIdsList = (state, id) =>
  id != null ? getProjectArchivedSubprojectsIdsHash(state)[id] ?? emptyArray : emptyArray
export const isArchivedSubprojectLoaded: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => boolean = (state, id) => {
  const archivedSubprojectsIds = getProjectArchivedSubprojectsIds(state, id)
  const projects = getProjectsHash(state)

  for (let i = 0; i < archivedSubprojectsIds.length; i++) {
    if (!projects[archivedSubprojectsIds[i].id]) {
      return false
    }
  }

  return true
}
export const isArchivedSubprojectLoadedForAllProjects: (arg0: State) => boolean = state => {
  const projects = getProjectsHash(state)
  const projectIds = Object.keys(projects).map(toProjectId)

  for (let i = 0; i < projectIds.length; i++) {
    const subprojects = projects[projectIds[i]]?.projects?.project || emptyArray

    for (let j = 0; j < subprojects.length; j++) {
      if (!projects[subprojects[j].id]) {
        return false
      }
    }
  }

  return true
}
export const getProjectsFetchable: (
  arg0: State,
  arg1?: ProjectId,
) => Fetchable<ReadonlyArray<ProjectId>> = (state, rootProjectId = ROOT_PROJECT_ID) =>
  state.projects[rootProjectId] ?? emptyArrayFetchable
export const isSidebarArchivedProjectsLoaded: (arg0: State, arg1: ProjectId) => boolean = (
  state,
  rootProjectId,
) =>
  getProjectsFetchable(state, rootProjectId).receiveMeta?.sidebarArchivedProjectsLoaded === true ||
  getProjectsFetchable(state, ROOT_PROJECT_ID).receiveMeta?.sidebarArchivedProjectsLoaded === true
export const isSidebarFederationArchivedProjectsLoaded: (
  arg0: State,
  arg1: FederationServerId,
) => boolean = (state, serverId) => {
  const serverData = state.federationServersData[serverId]
  return Boolean(serverData && serverData.projects.receiveMeta?.sidebarArchivedProjectsLoaded)
}
export const getOverviewProjectsHash: (
  arg0: State,
) => KeyValue<ProjectId, NormalizedProjectType> = state => state.entities.overviewProjects
export const getOverviewProject: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => NormalizedProjectType | null | undefined = (state, id) =>
  id != null ? getOverviewProjectsHash(state)[id] : null
export const getOverviewProjectParents: (
  arg0: State,
  arg1: ProjectId,
) => ReadonlyArray<ProjectId> = (state, id) => {
  const parentProjectId = getOverviewProject(state, id)?.parentProjectId

  if (!parentProjectId) {
    return []
  }

  return [...getOverviewProjectParents(state, parentProjectId), parentProjectId]
}
export const getUseRawLocator: (arg0: State) => boolean = state =>
  Boolean(state.buildsFilters.useRawLocator)
export const getShowQueuedBuildsInBuildsList: (arg0: State) => boolean = state =>
  state.showQueuedBuildsInBuildsList
export const getRawLocator: (arg0: State) => string | null | undefined = state =>
  getUseRawLocator(state) ? state.buildsFilters.locator : null
export const getIsExperimentalUI: (arg0: State) => boolean = state => state.isExperimentalUI
export const getTagFilter: (arg0: State) => string | null | undefined = state =>
  getUseRawLocator(state) ? null : state.buildsFilters.tag
export const getAgentPatternFilter: (arg0: State) => string | null | undefined = state =>
  getUseRawLocator(state) ? null : state.buildsFilters.agentPattern
export const getStateFilter: (arg0: State) => BuildState = state =>
  getUseRawLocator(state) ? 'all' : state.buildsFilters.buildState ?? 'all'

const getBranchesFetchable: (
  arg0: State,
  arg1: string,
) => Fetchable<ReadonlyArray<BranchType>> | null | undefined = (state, endpoint) =>
  state.branches[endpoint]

export const getBranchesReady: (arg0: State, arg1: string) => boolean = (state, endpoint) =>
  Boolean(getBranchesFetchable(state, endpoint)?.ready)
export const getProjectsReady: (arg0: State) => boolean = state =>
  Boolean(getProjectsFetchable(state, ROOT_PROJECT_ID).ready)
export const getBranches: (
  arg0: State,
  arg1: string,
) => ReadonlyArray<BranchType> | null | undefined = (state, endpoint) => {
  const fetchable = getBranchesFetchable(state, endpoint) ?? emptyArrayFetchable
  return fetchable.ready ? fetchable.data : null
}
export const getIsBranchPresent: (arg0: State, arg1: string) => boolean | null | undefined = (
  state,
  endpoint,
) => state.isBranchPresent[endpoint]?.data
export const getIsBranchPresentInited: (arg0: State, arg1: string) => boolean = (state, endpoint) =>
  state.isBranchPresent[endpoint]?.inited ?? false
export const createBuildTypeNode = (buildTypeId: BuildTypeId): ProjectOrBuildTypeNode => ({
  nodeType: 'bt',
  id: buildTypeId,
})

const getBuildTypeBranches: (
  arg0: State,
  arg1: BuildTypeId,
  arg2: FetchBranchesParams | null | undefined,
) => ReadonlyArray<BranchType> | null | undefined = (state, buildTypeId, params) =>
  getBranches(
    state,
    getEndpointForBranches({
      node: createBuildTypeNode(buildTypeId),
      ...params,
    }).data,
  )

const hasNonDefaultBranches = (branches: ReadonlyArray<BranchType> | null | undefined) =>
  branches != null && branches.some(branch => branch.default !== true && !branch.groupFlag)

export const hasBranches: (arg0: State, arg1: string) => boolean = (state, endpoint) =>
  hasNonDefaultBranches(getBranches(state, endpoint))
export const buildTypeHasBranches = (
  state: State,
  buildTypeId: BuildTypeId,
  params?: FetchBranchesParams,
) =>
  hasNonDefaultBranches(getBuildTypeBranches(state, buildTypeId, params)) ||
  getIsBranchPresent(
    state,
    getEndpointForBranches({
      node: createBuildTypeNode(buildTypeId),
      excludeGroups: true,
      excludeDefault: true,
      ...params,
    }).presence,
  ) === true

const getFullPathPart: (
  arg0: State,
  arg1: ProjectOrBuildTypeNode | null | undefined,
) => FullPathCacheItem = (state, node) => {
  const result = {
    fullPath: emptyArray,
    isFullyLoaded: false,
  }

  if (node == null || node.nodeType === 'all' || node.id === ROOT_PROJECT_ID) {
    result.isFullyLoaded = true
    return result
  }

  const cacheKey = `${node.nodeType}_${stringifyId(node.id)}`
  const cached: FullPathCacheItem | null | undefined = memoryCache.get('fullPath', cacheKey)

  if (cached?.isFullyLoaded === true) {
    return cached
  }

  if (node.nodeType === 'bt') {
    const buildType = getBuildType(state, node.id)

    if (buildType == null) {
      result.fullPath = [
        {
          id: node.id,
          name: stringifyId(node.id),
          projectId: ROOT_PROJECT_ID,
        },
      ]
    } else {
      const links = getBuildTypeLinks(state, node.id)
      const item: BuildTypePathItem = links != null ? {...buildType, ...links} : {...buildType}
      const parentPart = getFullPathPart(state, {
        nodeType: 'project',
        id: buildType.projectId,
      })
      result.fullPath = parentPart.fullPath.concat(item)
      result.isFullyLoaded = parentPart.isFullyLoaded
    }
  } else {
    const project = getProject(state, node.id)

    if (project == null) {
      return result
    }

    const links = getProjectLinks(state, node.id)
    const item: ProjectPathItem = links != null ? {...project, ...links} : {...project}
    const {parentProjectId} = project

    if (parentProjectId == null) {
      result.fullPath = [item]
      result.isFullyLoaded = true
    } else {
      const parentPart = getFullPathPart(state, {
        nodeType: 'project',
        id: parentProjectId,
      })
      result.fullPath = parentPart.fullPath.concat(item)
      result.isFullyLoaded = parentPart.isFullyLoaded
    }
  }

  if (cached != null && result.fullPath.length === cached.fullPath.length) {
    return cached
  }

  memoryCache.set('fullPath', cacheKey, result)
  return result
}

export const getFullPath: (
  arg0: State,
  arg1: ProjectOrBuildTypeNode | null | undefined,
) => FullPath = (state, node) => getFullPathPart(state, node).fullPath
export const getFullPathReady: (
  arg0: State,
  arg1: ProjectOrBuildTypeNode | null | undefined,
) => boolean = (state, node) => getFullPathPart(state, node).isFullyLoaded
export const getProjectBuildtypeFilter: (arg0: State) => ProjectOrBuildTypeNode | null | undefined =
  createSelector(
    (state: State) => state.buildsFilters.projectBuildtype,
    (state: State) => getFullPath(state, state.buildsFilters.projectBuildtype),
    (projectBuildtype, fullPath) => {
      if (!projectBuildtype) {
        return null
      }

      const clone = cloneNode(projectBuildtype)

      if (clone.nodeType === 'all') {
        return projectBuildtype
      }

      clone.fullPath = fullPath.map(item => item.name).join(' :: ')
      return clone
    },
  )
export const getProjectFilter: (arg0: State) => ProjectId | null | undefined = state => {
  if (getUseRawLocator(state)) {
    return null
  }

  const {projectBuildtype} = state.buildsFilters
  return projectBuildtype && projectBuildtype.nodeType === 'project' ? projectBuildtype.id : null
}
export const getBuildtypeFilter: (arg0: State) => BuildTypeId | null | undefined = state => {
  if (getUseRawLocator(state)) {
    return null
  }

  const {projectBuildtype} = state.buildsFilters
  return projectBuildtype && projectBuildtype.nodeType === 'bt' ? projectBuildtype.id : null
}
const getAgentFilter: (arg0: State) => AgentFilter | null | undefined = state =>
  state.buildsFilters.agent
export const getAgentIdFilter: (arg0: State) => AgentId | null | undefined = state =>
  getAgentFilter(state)?.id ?? null
export const getAgentTypeIdFilter: (arg0: State) => AgentTypeId | null | undefined = state =>
  getAgentFilter(state)?.typeId ?? null
export const getCurrentUser = (state: State): CurrentUserType | null => state.currentUser.data
export const getCurrentUserLoaded: (arg0: State) => boolean = state =>
  getCurrentUser(state) != null && state.currentUser.inited && !state.currentUser.loading
export const getCurrentUserId: (arg0: State) => UserId | null | undefined = state =>
  getCurrentUser(state)?.id
export const getUserDisplayName: (
  state: State,
  user?: User,
  capitalizeYou?: boolean,
) => string | null | undefined = (state, user, capitalizeYou) => {
  if (!user) {
    return null
  }

  if (user.id != null && user.id === getCurrentUserId(state)) {
    return capitalizeYou === true ? 'You' : 'you'
  }

  return user.name ?? user.username
}
export const getBuildsData: (
  arg0: State,
  arg1: string | null | undefined,
) => Fetchable<ReadonlyArray<BuildId>> = (state, locator) =>
  (locator != null && state.builds[locator]) || emptyArrayFetchable
export const getBuildsHash: (
  state: State,
) => KeyValue<BuildId, NormalizedBuildType | null> = state => state.entities.builds
export const getBuild = (
  state: State,
  id: BuildId | null | undefined,
): NormalizedBuildType | null | undefined => (id != null ? getBuildsHash(state)[id] : null)
export const getBuildTestOccurrencesCount = (
  state: State,
  id: BuildId | null | undefined,
): number | null | undefined => (id != null ? state.entities.buildsTestOccurrencesCount[id] : null)
export function getBuildAgent(state: State, id: BuildId): NormalizedAgentType | null | undefined {
  const build = getBuild(state, id)
  return build?.state === 'queued'
    ? build?.plannedAgent?.typeId === 0
      ? null
      : build?.plannedAgent
    : build?.agent
}
const getBuildLinks: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => WebLinks | null | undefined = (state, id) => {
  const links = getBuild(state, id)?.links
  return links != null
    ? {
        links,
      }
    : null
}
export const getSnapshotDependencies = (state: State): KeyValue<BuildId, ReadonlyArray<BuildId>> =>
  state.entities.snapshotDependencies
export const getBuildSnapshotDependencies: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => ReadonlyArray<BuildId> = (state, id) =>
  id != null ? getSnapshotDependencies(state)[id] ?? emptyArray : emptyArray

export const getSnapshotDependenciesLoaded: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => boolean = (state, id) => id != null && state.entities.snapshotDependencies[id] != null
export const getBuildTriggered: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => NormalizedBuildTriggeredType | null | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build ? build.triggered : null
}
export const getBuildTriggerBuild: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => BuildTriggeredBuildType | null | undefined = (state, id) =>
  (id != null && state.buildTriggerBuilds[id]) || null
export const getBuildComment: (
  arg0: State,
  arg1: BuildId | null | undefined,
) =>
  | (CommentInfo & {
      readonly buildTypeId: BuildTypeId
    })
  | null
  | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build
    ? {
        buildTypeId: build.buildType,
        ...build.comment,
      }
    : null
}
export const getBuildPinInfo: (
  arg0: State,
  arg1: BuildId | null | undefined,
) =>
  | (CommentInfo & {
      readonly buildTypeId: BuildTypeId
    })
  | null
  | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build
    ? {
        buildTypeId: build.buildType,
        ...build.pinInfo,
      }
    : null
}
export const getBuildCanceledInfo: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => CommentInfo | null | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build ? build.canceledInfo : null
}
export const makeGetMaybeBuilds: () => (
  state: State,
  locator: string | null | undefined,
) => ReadonlyArray<NormalizedBuildType | null | undefined> = () =>
  createSelector(
    [
      getBuildsHash,
      (state: State, locator: string | null | undefined) => getBuildsData(state, locator).data,
    ],
    (hash: KeyValue<BuildId, NormalizedBuildType | null>, data: ReadonlyArray<BuildId>) =>
      data.map(id => hash[id]),
  )
export const makeGetBuilds: () => (
  state: State,
  locator: string | null | undefined,
) => ReadonlyArray<NormalizedBuildType> = () =>
  createSelector(makeGetMaybeBuilds(), builds => builds.filter(notNull))
// This selector is suitable only for components that use the main locator of page (pager, filters, etc)
const getBuilds = makeGetBuilds()

export const getBuildsLocatorWithPage = (state: State, locator: string) => {
  const pager = getPager(state, PagerGroup.BUILD)
  const offset = getOffset(pager)
  const count = pager.pageSize
  return `${locator},start:${offset},count:${count},lookupLimit:${String(pager.lookupLimit)}`
}

const filterBuildsByPage = (
  currentPage: number,
  pageSize: number,
  builds: readonly (NormalizedBuildType | null | undefined)[],
) => builds.slice((currentPage - 1) * pageSize, currentPage * pageSize).filter(notNull)

export const getBuildsFilteredByPage: (
  state: State,
  builds: readonly (NormalizedBuildType | null | undefined)[],
) => NormalizedBuildType[] = createSelector(
  (state: State) => getCurrentPage(state, PagerGroup.BUILD),
  (state: State) => getPager(state, PagerGroup.BUILD).pageSize,
  (state: State, builds: readonly (NormalizedBuildType | null | undefined)[]) => builds,
  filterBuildsByPage,
)

export const getBuildsLoading = (state: State, locator?: string | null): boolean =>
  getBuildsData(state, locator).loading
export const getBuildsInited = (state: State, locator?: string): boolean =>
  getBuildsData(state, locator).inited
export const getBuildsBackgroundLoading: (
  arg0: State,
  arg1: string | null | undefined,
) => boolean = (state, locator) => getBuildsData(state, locator).backgroundLoading
export const getBuildsHasError: (state: State, locator?: string | null) => boolean = (
  state,
  locator,
) => getBuildsData(state, locator).error != null
export const getBuildsReady = (
  state: State,
  locator?: string | null,
  withPath?: boolean,
): boolean =>
  getBuildsData(state, locator).ready &&
  (withPath !== true || getProjectsFetchable(state, ROOT_PROJECT_ID).ready)
export const getSomeBuildsLoaded: (arg0: State, arg1: string | null | undefined) => boolean = (
  state,
  locator,
) => getBuildsData(state, locator).data.length > 0
const getBuildArtifactsHash: (
  arg0: State,
) => KeyValue<BuildId, NormalizedBuildType | null> = state => state.entities.buildArtifacts

const hasArtifactsInBuild: (
  arg0: BuildArtifactsType | null | undefined,
) => boolean | null | undefined = build => {
  const {artifacts} = build || {}
  return artifacts && artifacts.count != null ? artifacts.count > 0 : null
}

export const getHasArtifacts: (arg0: State, arg1: BuildId) => boolean | null | undefined = (
  state,
  id,
) => {
  const hasArtifacts = hasArtifactsInBuild(getBuild(state, id))
  return hasArtifacts != null ? hasArtifacts : hasArtifactsInBuild(getBuildArtifactsHash(state)[id])
}
export const getHasArtifactsState: (
  arg0: State,
  arg1: BuildId,
) => BuildStateType | null | undefined = (state, id) => {
  const build = getBuild(state, id)
  const hasArtifacts = hasArtifactsInBuild(build)
  const buildToCheck: NormalizedBuildType | null | undefined =
    hasArtifacts != null ? build : getBuildArtifactsHash(state)[id]
  return buildToCheck?.state
}

const getBuildCompatibleAgentsHash: (
  arg0: State,
) => KeyValue<BuildId, CompatibleAgentType | null> = state => state.entities.compatibleAgents

export const getBuildCompatibleAgents: (
  arg0: State,
  arg1: BuildId,
) => CompatibleAgentType | null | undefined = (state, id) => getBuildCompatibleAgentsHash(state)[id]
export const getIsMine: (arg0: State, arg1: BuildId | null | undefined) => boolean = (
  state,
  buildId,
) => {
  const userId = getBuild(state, buildId)?.user?.id
  const myId = getCurrentUserId(state)
  return userId != null && userId === myId
}
export const getIsGuestOrRoot: (state: State) => boolean = state =>
  Number(getCurrentUserId(state)) < 0
export function hasPermission(
  state: State,
  permission: Permission,
  projectId: ProjectId,
  nullable: true,
): boolean | null
export function hasPermission(
  state: State,
  permission: Permission,
  projectId?: ProjectId,
  nullable?: false,
): boolean
export function hasPermission(
  state: State,
  permission: Permission,
  projectId: ProjectId = ROOT_PROJECT_ID,
  nullable: boolean = false,
): boolean | null {
  return state.permissions[permission]?.[projectId] ?? (nullable ? null : false)
}
export const getUserProperty = (state: State, propertyName: UserProperty) =>
  state.userProperties[propertyName]
export const getBooleanUserProperty: (
  state: State,
  propertyName: UserProperty,
  defaultTrue?: boolean,
) => boolean = (state, propertyName, defaultTrue) => {
  const value = getUserProperty(state, propertyName)
  return defaultTrue === true ? value !== 'false' : value === 'true'
}
export const getIsHightlightMyChangesAndInvestigations: (arg0: State) => boolean = state =>
  getBooleanUserProperty(state, UserProperties.HIGHLIGHT_MY_CHANGES, true)
export const getIsAutodetectTimeZone: (state: State) => boolean = state =>
  getBooleanUserProperty(state, UserProperties.AUTODETECT_TIMEZONE)
export const isDialogShown: (state: State, dialogId?: string, type?: DialogType) => boolean = (
  state,
  dialogId = '',
  type,
) => state.dialog.opened === true && state.dialog.type === type && state.dialog.id === dialogId
export const isDialogProcessing: (arg0: State) => boolean = state => !!state.dialog.processing
export const dialogHasError: (arg0: State) => boolean = state => !!state.dialog.error
const getHideOtherPersonalBuilds: (arg0: State) => boolean = state =>
  !getBooleanUserProperty(state, UserProperties.SHOW_ALL_PERSONAL_BUILDS)

const getEntitySelector: (
  node?: ProjectOrBuildTypeNode | null | undefined,
  onlyFavorites?: boolean,
) => string = (node, onlyFavorites) => {
  if (!node || !node.id) {
    return ''
  }

  return [
    node.nodeType === 'project' ? `project:${stringifyId(node.id)}` : `id:${stringifyId(node.id)}`,
    onlyFavorites === true ? 'selectedByUser:(user:current)' : null,
  ]
    .filter(Boolean)
    .join(',')
}

const getFinishedItemLocator = ({
  selector,
  branchLocatorWithSeparator,
  count,
}: {
  selector: string
  branchLocatorWithSeparator: string
  count: number | undefined
}) =>
  `item:(${
    (count != null && count > 0 && `count:${count},`) || ''
  }strob:(buildType:(${selector})${branchLocatorWithSeparator}))`

const getQueuedItemLocator = ({
  selector,
  branchLocatorWithSeparator,
}: {
  selector: string
  branchLocatorWithSeparator: string
}) => `item:(state:queued,buildType:(${selector})${branchLocatorWithSeparator})`

const getRunningItemLocator = ({
  selector,
  branchLocatorWithSeparator,
}: {
  selector: string
  branchLocatorWithSeparator: string
}) => `item:(state:running,buildType:(${selector})${branchLocatorWithSeparator})`

export const getPersonalBuildsLocator: (arg0: State) => string | null | undefined = (
  state: State,
) =>
  getHideOtherPersonalBuilds(state) ? 'or:(personal:false,and:(personal:true,user:current))' : null
export const getProjectOverviewLocator: (arg0: State, arg1: LocatorOptions) => string = (
  state,
  options,
) => {
  const {branch, projectBuildtype, runningCount, queuedCount, finishedCount} = options
  const totalCount = (runningCount || 0) + (queuedCount || 0) + (finishedCount || 0)
  const branchLocator = branch ? getBranchLocator(branch) : ''
  const selector = getEntitySelector(projectBuildtype)
  const branchLocatorWithSeparator = branchLocator
    ? `,${branchLocator}`
    : ',branch:(policy:ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES)'
  const finishedItem = getFinishedItemLocator({
    branchLocatorWithSeparator,
    selector,
    count: finishedCount,
  })
  const queuedItem = getQueuedItemLocator({
    branchLocatorWithSeparator,
    selector,
  })
  const runningItem = getRunningItemLocator({
    branchLocatorWithSeparator,
    selector,
  })
  const locator = [queuedItem, runningItem, finishedItem].join(',')
  return `count:${totalCount},${locator}`
}

export const getLocator: (arg0: State, arg1: LocatorOptions) => string = (state, options) => {
  const {
    baseLocator,
    projectBuildtype,
    agent,
    agentPattern,
    tag,
    buildState,
    withRunningAndQueued,
    runningAndFinished,
    branch,
  }: LocatorOptions = Object.entries(options).reduce(
    (acc: LocatorOptions, [key, value]) => (value !== undefined ? {...acc, [key]: value} : acc),
    {...state.buildsFilters},
  )
  const rawLocator = getRawLocator(state)

  if (rawLocator != null) {
    return rawLocator
  }

  const branchLocator = branch ? getBranchLocator(branch) : ''
  return [
    'defaultFilter:false',
    branchLocator,
    baseLocator,
    agent?.typeId != null && `agentTypeId:${stringifyId(agent.typeId)}`,
    agent?.id != null && `agent:(id:${stringifyId(agent.id)})`,
    agentPattern != null &&
      `agentName:(value:${escapeLocatorDimension(
        agentPattern,
      )},matchType:contains,ignoreCase:true)`,
    runningAndFinished !== true && buildState && stateLocators[buildState],
    withRunningAndQueued !== true &&
      runningAndFinished !== true &&
      (buildState == null || !['running', 'queued'].includes(buildState)) &&
      'state:finished',
    runningAndFinished === true && stateLocators.runningAndFinished,
    projectBuildtype &&
      projectBuildtype.nodeType === 'project' &&
      `affectedProject:(id:${stringifyId(projectBuildtype.id)})`,
    projectBuildtype &&
      projectBuildtype.nodeType === 'bt' &&
      `buildType:(id:${stringifyId(projectBuildtype.id)})`,
    tag != null && `tag:(name:${escapeLocatorDimension(tag)})`,
    getPersonalBuildsLocator(state),
  ]
    .filter(Boolean)
    .join(',')
}
type HistoryLocatorOptions = {
  buildTypeId: BuildTypeId
  branch?: BranchType | null | undefined
  buildState?: BuildStateType | null | undefined
}
export const getHistoryLocator: (arg0: State, arg1: HistoryLocatorOptions) => string = (
  state,
  {buildTypeId, branch, buildState},
) =>
  [
    'defaultFilter:false',
    `buildType:(id:${stringifyId(buildTypeId)})`,
    branch && getBranchLocator(branch),
    buildState != null && `state:${buildState}`,
    getPersonalBuildsLocator(state),
  ]
    .filter(Boolean)
    .join(',')
export const getHasBuilds: (arg0: State, arg1: string) => boolean = (state, locator) =>
  state.hasBuilds[locator]?.data ?? false
export const getHasBuildsInited: (arg0: State, arg1: string) => boolean = (state, locator) =>
  state.hasBuilds[locator]?.inited ?? false
export const getHasBuildsLoading: (arg0: State, arg1: string) => boolean = (state, locator) =>
  !state.hasBuilds[locator]?.ready ?? true

const getLocatorReady: (arg0: State) => boolean = state => Boolean(state.buildsFilters.locatorReady)

export const getLocatorIfReady: (arg0: State, arg1: LocatorOptions) => string | null | undefined = (
  state,
  options,
) => (getLocatorReady(state) ? getLocator(state, options) : null)

const getAllTags: (arg0: State, arg1: BuildId | null | undefined) => ReadonlyArray<TagType> = (
  state,
  buildId,
) => getBuild(state, buildId)?.tags?.tag ?? emptyArray

// If you need this array as a prop, use makeGetTags factory instead
export const getTags: (arg0: State, arg1: BuildId | null | undefined) => ReadonlyArray<TagType> = (
  state,
  buildId,
) => getAllTags(state, buildId).filter(tag => !tag.private)
export const getJoinedTags: (arg0: State, arg1: BuildId | null | undefined) => string = (
  state,
  buildId,
) =>
  getTags(state, buildId)
    .map(tag => tag.name)
    .join(' ')
export const getTagsCount: (arg0: State, arg1: BuildId | null | undefined) => number = (
  state,
  buildId,
) => getTags(state, buildId).length
export const getTagsLabel: (arg0: State, arg1: BuildId) => string = (state, buildId) => {
  const count = getTagsCount(state, buildId)

  switch (count) {
    case 0:
      return ''

    case 1:
      return getTags(state, buildId)[0].name

    default:
      return count.toString()
  }
}
export const makeGetTags: () => (
  arg0: State,
  arg1: BuildId | null | undefined,
) => ReadonlyArray<TagType> = () =>
  createSelector(getAllTags, tags => tags.filter(tag => !tag.private))
export const makeGetTagNames: () => (
  arg0: State,
  arg1: BuildId | null | undefined,
) => ReadonlyArray<string> = () => createSelector(makeGetTags(), tags => tags.map(tag => tag.name))
export const getIsStarred: (arg0: State, arg1: BuildId | null | undefined) => boolean = (
  state,
  buildId,
) => {
  if (buildId == null) {
    return false
  }

  const starring = state.starringBuilds[buildId]

  if (starring != null) {
    return starring
  }

  const {tags} = getBuild(state, buildId) || {}
  return (tags && tags.tag && tags.tag.some(tag => tag.private && tag.name === STAR_TAG)) || false
}
export const getIsStopping: (arg0: State, arg1: BuildId) => boolean = (state, buildId) =>
  !!state.stoppingBuilds[buildId]
export const getHasDependencies: (arg0: State, arg1: BuildId | null | undefined) => boolean = (
  state,
  buildId,
) => {
  const deps = getBuild(state, buildId)?.['snapshot-dependencies']
  return deps && deps.count != null ? deps.count > 0 : false
}

const getAgentsHash: (arg0: State) => KeyValue<AgentId, NormalizedAgentType | null> = state =>
  state.entities.agents

export const getAgent: (arg0: State, arg1: AgentId) => NormalizedAgentType | null | undefined = (
  state,
  id,
) => getAgentsHash(state)[id]
export const getAgents = createSelector(
  (state: State, locator: string, options: AgentRequestOptions) =>
    restApi.endpoints.getAllAgentsNormalized.select(getAgentsArg(locator, options))(state).data,
  data => data?.result.map(id => data.entities.agents?.[id]).filter(notNull) ?? emptyArray,
)
export const getAgentsReady = (state: State, locator: string, options: AgentRequestOptions) =>
  restApi.endpoints.getAllAgentsNormalized.select(getAgentsArg(locator, options))(state)
    .isSuccess && getProjectsFetchable(state, ROOT_PROJECT_ID).ready
export const getAgentPoolsReady: (arg0: State) => boolean = state => state.agentPools.ready
export const getAgentPoolsError: (arg0: State) => Error | null | undefined = state =>
  state.agentPools.error
export const getAgentPools: (arg0: State) => AgentPoolsHashType = state => state.entities.agentPools
export const getAgentPool: (arg0: State, arg1: AgentPoolId) => AgentPoolType | null | undefined = (
  state,
  agentPoolId,
) => state.entities.agentPools[agentPoolId]
export const getAgentPoolsOrder: (arg0: State) => ReadonlyArray<AgentPoolId> = ({agentPools}) =>
  agentPools.data || emptyArray
// If there's "view agent details" permission, the API returns the full list of agent pools.
// The same happens if there's "view project agent details" permission, for any project (which apparently is a bug).
// If there're no above permissions, the API returns empty list of agent pools.
// It seems safe to use this fact to detect the lack of permissions.
export const getCanViewAgentDetailsReady = getAgentPoolsReady
export const getCanViewAgentDetails: (arg0: State) => boolean = createSelector(
  (state: State) => objectValues(state.entities.agentPools).length > 0,
  canViewAgentDetails => canViewAgentDetails,
)
export const getSingleAgentFetchable = (
  state: State,
  agentId: AgentId,
): Fetchable<AgentId | null | undefined> | null | undefined => state.agent[agentId]
export const getSingleAgent: (
  arg0: State,
  arg1: AgentId,
) => NormalizedAgentType | null | undefined = (state, agentId) => state.entities.agent[agentId]
export const getSingleAgentReady: (arg0: State, arg1: AgentId) => boolean = (state, agentId) =>
  Boolean(getSingleAgentFetchable(state, agentId)?.ready)
export const getSingleAgentError: (
  arg0: State,
  arg1: AgentId,
) =>
  | {
      readonly statusCode?: number
      readonly message?: string
      code?: number
    }
  | null
  | undefined = (state, agentId) => getErrorIfReady(getSingleAgentFetchable(state, agentId))
const getAgentPreviewsResult = restApi.endpoints.getAllAgentPreviewsNormalized.select(
  getAgentPreviewsArg(),
)
const getAgentPreviewsReady = (state: State): boolean => getAgentPreviewsResult(state).isSuccess
const getAgentPreviews: (state: State) => AgentPreviewsHashType | null | undefined = createSelector(
  getAgentPreviewsReady,
  (state: State) => getAgentPreviewsResult(state).data?.result ?? emptyArray,
  (state: State) => state.entities.agentPreviews,
  (
    ready: boolean,
    agentIds: ReadonlyArray<AgentId>,
    entities: AgentPreviewsHashType,
  ): AgentPreviewsHashType | null | undefined =>
    ready
      ? agentIds.reduce((hash: WritableAgentPreviewsHashType, agentId: AgentId) => {
          hash[agentId] = entities[agentId]
          return hash
        }, {})
      : null,
)
export const getAllAgentPreviews = (state: State): AgentPreviewsHashType =>
  getAgentPreviews(state) || getEmptyHash()
const getCloudImage: (
  arg0: State,
  arg1: AgentTypeId,
) => NormalizedCloudImageType | null | undefined = (state, agentTypeId) =>
  state.entities.cloudImages != null ? state.entities.cloudImages[agentTypeId] : null
export const getSingleCloudImage: (
  arg0: State,
  arg1: AgentTypeId,
) => NormalizedCloudImageType | null | undefined = (state, agentTypeId) =>
  state.entities.cloudImage[agentTypeId]
export const getAllAgentDataReady: (arg0: State) => boolean = state =>
  getAgentPoolsReady(state) && getAgentPreviewsReady(state)
export const getCanChangeStatusPoolPermission = (state: State, poolId: AgentPoolId): boolean =>
  state.poolPermissions.canChangeStatus[poolId] ?? false
export const getCanAuthorizePoolPermission: (arg0: State, arg1: AgentPoolId) => boolean = (
  state,
  poolId,
) => state.poolPermissions.canAuthorize[poolId] ?? false
export const getSorting: (arg0: State) => Sorting = state => state.sorting
export const getSortingDimension: (arg0: State) => string = state => getSorting(state).dimension
export const getSortingDescending: (arg0: State) => boolean = state => getSorting(state).descending
export const getAgentInCloud: (arg0: State, arg1: AgentId) => boolean = (state, agentId) =>
  state.agentsInCloud[agentId] ?? false
const getBuildsOwnerUserName: (arg0: State, arg1: BuildId) => string | null | undefined = (
  state,
  buildId,
) => getUserDisplayName(state, getBuild(state, buildId)?.user)

const getBlocksHash: (arg0: State, arg1: CollapsibleBlock) => KeyValue<string | Id, boolean> = (
  state,
  block,
) => state.blocks[block] ?? getEmptyHash()

export const getBlocks: (arg0: State, arg1: CollapsibleBlock) => ReadonlyArray<string | Id> =
  createSelector(getBlocksHash, hash => Object.keys(hash).filter(id => hash[id]))
const getIsBlockIncluded: (
  state: State,
  block: CollapsibleBlock,
  id: string | Id,
  defaultTrue?: boolean,
) => boolean = (state, block, id, defaultTrue) =>
  getBlocksHash(state, block)[id] ?? defaultTrue === true
const getOverviewProjectIds: (arg0: State) => ReadonlyArray<ProjectId> = state =>
  state.overview.data
export const makeGetFavoriteSubProjectIds: () => (
  state: State,
  projectId: ProjectId,
) => ReadonlyArray<ProjectId> = () =>
  createSelector(
    getProjectsHash,
    getOverviewProjectIds,
    (_: State, projectId: ProjectId) => projectId,
    (projects, favoriteIds, projectId) => {
      function isChild(childId: ProjectId | null | undefined): boolean {
        if (childId == null || childId === ROOT_PROJECT_ID) {
          return false
        }

        const {parentProjectId} = projects[childId] || {}

        if (parentProjectId === projectId) {
          return true
        }

        if (parentProjectId != null && favoriteIds.includes(parentProjectId)) {
          return false
        }

        return isChild(parentProjectId)
      }

      return favoriteIds.filter(isChild)
    },
  )
export const makeGetIsSubprojectsExist: () => (
  state: State,
  projectId: ProjectId,
) => boolean = () =>
  createSelector(
    getProjectsHash,
    (_: State, projectId: ProjectId) => projectId,
    (projects, projectId) =>
      objectValues(projects).some(project => project?.parentProjectId === projectId),
  )
export const makeGetDirectNotArchivedSubProjectIds: () => (
  state: State,
  projectId: ProjectId,
) => ReadonlyArray<ProjectId> = () =>
  createSelector(
    getProjectsHash,
    (_: State, projectId: ProjectId) => projectId,
    (projects, projectId) =>
      objectEntries(projects)
        .filter(([_, project]) => project?.parentProjectId === projectId && !project.archived)
        .map(([key]) => toProjectId(key)),
  )
export const makeGetDirectArchivedSubProjectIds: () => (
  state: State,
  projectId: ProjectId,
) => ReadonlyArray<ProjectId> = () =>
  createSelector(
    getProjectsHash,
    (_: State, projectId: ProjectId) => projectId,
    (projects, projectId) =>
      objectEntries(projects)
        .filter(([_, project]) => project?.parentProjectId === projectId && project.archived)
        .map(([key]) => toProjectId(key)),
  )
export const getBuildTypeTags: (arg0: State, arg1: BuildTypeId) => ReadonlyArray<string> = (
  state,
  buildTypeId,
) => state.buildTypeTags[buildTypeId]?.data || emptyArray
export const getBuildTypeTagsReady: (arg0: State, arg1: BuildTypeId) => boolean = (
  state,
  buildTypeId,
) => !!state.buildTypeTags[buildTypeId]?.ready
export const getBuildTypeTagsIfReady: (
  arg0: State,
  arg1: BuildTypeId,
) => ReadonlyArray<string> | null | undefined = createSelector(
  getBuildTypeTagsReady,
  getBuildTypeTags,
  (ready, tags) => (ready ? tags : null),
)
export const getHasDependants: (arg0: State, arg1: BuildId) => boolean | null | undefined = (
  state,
  buildId,
) => state.haveDependants[buildId]
export const getBuildTypesFromLoadedBuild: (
  state: State,
  locator?: string | null,
) => ReadonlyArray<BuildTypeId> = createSelector(getBuilds, builds =>
  builds.reduce<BuildTypeId[]>(
    (x, y) => (y.buildType != null && x.includes(y.buildType) ? x : [...x, y.buildType]),
    [],
  ),
)
export const getIsUpdating: (arg0: State) => boolean = state =>
  Boolean(state.buildsFilters.updating)
export const getBuildStatusTypeFromBuild = (
  build: Pick<Build, 'status' | 'state' | 'canceledInfo' | 'failedToStart'> | null | undefined,
) => {
  const {status, state: buildState, canceledInfo, failedToStart} = build || {}

  if (canceledInfo != null && buildState !== 'running') {
    return 'canceled'
  } else if (failedToStart === true) {
    return 'failure'
  } else if (buildState === 'queued') {
    return 'queued'
  } else {
    const isGreen = status == null || status === 'SUCCESS'
    return isGreen ? 'success' : 'failure'
  }
}
export const getBuildStatusType = (state: State, buildId: BuildId | undefined) =>
  getBuildStatusTypeFromBuild(getBuild(state, buildId))
export const getIsBuildDetached: (arg0: State, arg1: BuildId) => boolean | null | undefined = (
  state,
  buildId,
) => getBuild(state, buildId)?.detachedFromAgent

export const getBuildStatusIconFromBuild = (
  build:
    | Pick<
        Build,
        | 'status'
        | 'state'
        | 'canceledInfo'
        | 'failedToStart'
        | 'personal'
        | 'detachedFromAgent'
        | 'user'
      >
    | null
    | undefined,
  myId: UserId | null | undefined,
) => {
  if (build == null) {
    return 'help'
  }

  const {
    status,
    state: buildState,
    canceledInfo,
    failedToStart,
    personal,
    detachedFromAgent,
  } = build
  const modifiers = []

  if (personal === true) {
    const userId = build.user?.id
    modifiers.push(userId != null && userId === myId ? 'my' : 'personal')
  }

  if (canceledInfo != null && buildState !== 'running') {
    modifiers.push('canceled')
  } else if (failedToStart === true) {
    modifiers.push('failedToStart')
  } else if (buildState === 'queued') {
    modifiers.push(buildState)
  } else {
    if (buildState === 'running') {
      modifiers.push(buildState)

      if (detachedFromAgent) {
        modifiers.push('detached')
      }
    } else {
      modifiers.push('finished')
    }

    const isGreen = status == null || status === 'SUCCESS'
    modifiers.push(isGreen ? 'green' : 'red')
  }

  return modifiers.join('_')
}

export const getBuildStatusIcon: (arg0: State, arg1: BuildId | null | undefined) => string = (
  state,
  buildId,
) => {
  const build = getBuild(state, buildId)
  const myId = getCurrentUserId(state)
  return getBuildStatusIconFromBuild(build, myId)
}

const getBuildPrefix: (arg0: State, arg1: BuildId) => string = (state, buildId) => {
  const {personal} = getBuild(state, buildId) || {}
  const isMine = getIsMine(state, buildId)
  const ownerUserName = getBuildsOwnerUserName(state, buildId) || 'unknown user'

  if (personal !== true) {
    return 'Build'
  }

  if (isMine) {
    return 'Your personal build'
  }

  return `Personal build by ${ownerUserName}`
}

const getBuildStatusText: (arg0: State, arg1: BuildId) => string = (state, buildId) => {
  const build = getBuild(state, buildId)
  const {status, state: buildState, canceledInfo, failedToStart} = build || {}
  const canceledComment = canceledInfo?.text
  const canceledByUserName = getUserDisplayName(state, canceledInfo?.user)
  const isGreen = status == null || status === 'SUCCESS'

  if (canceledInfo != null) {
    return [
      'canceled',
      canceledByUserName != null && `by ${canceledByUserName}`,
      canceledComment != null && `with comment: ${canceledComment}`,
    ]
      .filter(Boolean)
      .join(' ')
  }

  if (buildState === 'queued') {
    return 'waiting in the queue'
  }

  if (buildState === 'running') {
    return `is ${isGreen ? 'running' : 'failing'}`
  }

  if (isGreen) {
    return 'was successful'
  }

  if (failedToStart === true) {
    return 'failed to start'
  }

  return 'failed'
}

export const getBuildStatusTooltip: (arg0: State, arg1: BuildId) => string = (state, buildId) =>
  `${getBuildPrefix(state, buildId)} ${getBuildStatusText(state, buildId)}`
export const getIsProjectOnOverview: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => boolean = (state, id) => {
  const toggling = id ? state.togglingOverview.project[id] : null
  return toggling != null ? toggling : id != null && getOverviewProjectIds(state).includes(id)
}
export const getIsBuildTypeOnOverview: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => boolean = (state, id) => {
  const toggling = id ? state.togglingOverview.bt[id] : null

  if (toggling != null) {
    return toggling
  }

  const projectId = getOwnerProjectId(state, id)

  if (!getIsProjectOnOverview(state, projectId)) {
    return false
  }

  const buildTypes = getOverviewProject(state, projectId)?.buildTypes?.buildType
  return buildTypes && id != null ? buildTypes.includes(id) : false
}

export const getStatuses: (arg0: State) => KeyValue<StatusKey, ProjectOrBuildTypeStatus> = state =>
  state.entities.buildTypeStatuses
export const getStatus: (
  arg0: State,
  arg1: StatusRequest | null | undefined,
) => ProjectOrBuildTypeStatus | null | undefined = (state, request) =>
  request && getStatuses(state)[getStatusKey(request)]
export const getStatusByKey: (
  arg0: State,
  arg1: StatusKey,
) => ProjectOrBuildTypeStatus | null | undefined = (state, statusKey) =>
  getStatuses(state)[statusKey]
export const getArtifactsFetchable = (
  state: State,
  id: BuildId,
  path?: string | null,
  withHidden?: boolean | null,
): Fetchable<ReadonlyArray<FileType>> =>
  state.artifacts[getArtifactsKey(id, path, withHidden)] ?? emptyArrayFetchable
const getHiddenArtifactsReady: (
  arg0: State,
  arg1: BuildId,
  arg2: string | null | undefined,
) => boolean = (state, id, path) => Boolean(getArtifactsFetchable(state, id, path, true)?.ready)
export const getHiddenArtifactsLoading: (
  arg0: State,
  arg1: BuildId,
  arg2: string | null | undefined,
) => boolean = (state, id, path) => Boolean(getArtifactsFetchable(state, id, path, true)?.loading)
export const getAvailableArtifacts: (
  arg0: State,
  arg1: BuildId,
  arg2: string | null | undefined,
) => ReadonlyArray<FileType> = (state, id, path) =>
  getArtifactsFetchable(state, id, path, getHiddenArtifactsReady(state, id, path))?.data ??
  emptyArray
export const getArtifactsExtensions: (
  arg0: State,
  arg1: BuildId,
) => ArtifactExtensions | null | undefined = (state, buildId) => state.artifactExtensions[buildId]
export const getIsBuildStarting: (
  arg0: State,
  arg1: BuildTypeId,
  arg2: BranchType | null | undefined,
) => boolean = (state, buildTypeId, branch) =>
  Boolean(state.startingBuilds[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))])
export const getExtensionEndpoint = (state: State, name: string) =>
  state.urlExtensions.find(e => e.name === name)
export const getExtensionEndpointsByKind: (
  arg0: State,
  arg1: string,
) => ReadonlyArray<UrlExtension<any>> = (state, kind) =>
  state.urlExtensions.filter(e => e.kind === kind)
export const generateMainUILinkWithQuery = (
  href: string | null | undefined,
  params: QueryParams = {},
  hash?: string,
): string => {
  const url = parseURL(href ?? '')
  url.search = objectToQuery({...queryToObject(url.search), ...params, fromSakuraUI: 'true'})

  if (hash != null) {
    url.hash = hash
  }

  return url.href
}
export const getMainUIFavoriteBuildsLink = (): string =>
  generateMainUILinkWithQuery(resolveRelative('/favoriteBuilds.html'))
export const getMainUIAgentsLink = (tab?: string | null | undefined): string =>
  generateMainUILinkWithQuery(resolveRelative('/agents.html'), {
    tab,
  })
export const getMainUIAgentLink = (
  id: AgentId,
  tab?: TabId | null | undefined,
  kind?: string | null | undefined,
): string =>
  generateMainUILinkWithQuery(resolveRelative('/agentDetails.html'), {
    id: String(id),
    tab: tab != null ? stringifyId(tab) : null,
    kind,
  })
export const getMainUIAgentPoolLink = (id: AgentPoolId): string =>
  generateMainUILinkWithQuery(
    resolveRelative('/agents.html'),
    {
      tab: 'agentPools',
    },
    String(id),
  )
export const getMainUICloudImageLink = (
  agentTypeId: AgentTypeId,
  tab?: string | null | undefined,
  kind?: string | null | undefined,
): string =>
  generateMainUILinkWithQuery(
    resolveRelative('/agentDetails.html', {
      agentTypeId,
      tab,
      kind,
    }),
  )
export const getMainUITestHistoryLink = ({
  testId,
  projectId,
  buildTypeId,
  branch,
}: {
  testId: TestId | null | undefined
  projectId: ProjectId | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  branch?: BranchType | null | undefined
}): string => {
  const params: WritableKeyValue<string, string | null | undefined> = {
    testNameId: stringifyId(testId),
    projectId: stringifyId(projectId),
    buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
    tab: 'testDetails',
  }

  if (branch != null) {
    params[`branch_${params.projectId}`] = stringifyBranch(branch)
  }

  return generateMainUILinkWithQuery(resolveRelative('/project.html'), params)
}

export const getMainUIChangeLink = ({
  changeId,
  buildTypeId,
  personal,
  tab,
}: {
  changeId: ChangeId
  buildTypeId?: BuildTypeId | null | undefined
  personal?: boolean | null | undefined
  tab?: OldChangePageTabNamesEnumType | null | undefined
}): string =>
  generateMainUILinkWithQuery(
    resolveRelative('/viewModification.html', {
      modId: stringifyId(changeId),
      buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
      personal: personal != null ? String(personal) : null,
      tab: tab ?? OldChangePageTabNamesEnum.FILES,
    }),
  )

export const getMainUIChangesLink = (): string =>
  generateMainUILinkWithQuery(resolveRelative('/changes.html'))

export const getMainUIDiffBetweenTwoFiles = ({
  changeId,
  personal,
  file,
}: {
  readonly changeId: ChangeId
  readonly personal?: boolean
  readonly file: string
}): string =>
  resolveRelative(`/diffView.html`, {
    id: stringifyId(changeId),
    vcsFileName: file,
    personal: personal != null ? personal.toString() : 'false',
  })

export const getMainUIQueueLink = (): string =>
  generateMainUILinkWithQuery(resolveRelative('/queue.html'))
export const getEntityWebLinks: (
  arg0: State,
  arg1: ActiveEntityProps,
) => WebLinks | null | undefined = (state, {projectId, buildTypeId, buildId}) =>
  getBuildLinks(state, buildId) ??
  getBuildTypeLinks(state, buildTypeId) ??
  getProjectLinks(state, projectId)
export const makeGetMainUILinkWithBranch = (
  {projectId, buildTypeId, buildId}: ActiveEntityURLProps,
  branch?: BranchType | null,
  params?: KeyValue<string, string | null | undefined> | null,
): ((state: State) => string) =>
  createSelector(
    (state: State) =>
      projectId === ROOT_PROJECT_ID
        ? null
        : getEntityWebLinks(state, {
            projectId,
            buildTypeId,
          }),
    (state: State) => projectId ?? getOwnerProjectId(state, buildTypeId),
    getFocusLine,
    getLogFilter,
    getExpandAll,
    (links, branchProjectId, focusLine, logFilter, expandAll) => {
      const href =
        buildId != null
          ? resolveRelative('/viewLog.html')
          : resolveWebEntityLink(links) ?? resolveRelative('/overview.html')
      const branchParam: string = buildId != null ? 'buildBranch' : getBranchParam(branchProjectId)
      return generateMainUILinkWithQuery(href, {
        ...params,
        buildId: buildId != null ? stringifyId(buildId) : null,
        buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
        [branchParam]: branch && stringifyBranch(branch),
        _focus: focusLine?.toString() ?? undefined,
        filter: logFilter?.toString() ?? undefined,
        expand: expandAll ? 'all' : undefined,
      })
    },
  )
export const getClientId: (arg0: State) => ClientId = state => state.clientId
export const getShowQueuedBuildsPerBranch: (arg0: State, arg1: StatusKey) => boolean = (
  state,
  key,
) => state.showQueuedBuildsPerBranch[key] ?? false
export const getShowQueuedBuildsCount = (
  state: State,
  buildTypeId: BuildTypeId,
  branch?: BranchType | null,
): number | undefined =>
  state.showQueuedBuildsCount[
    getStatusKey({
      type: 'bt',
      branch,
      id: buildTypeId,
    })
  ]
export const getShowQueuedBuildsInProject: (arg0: State, arg1: BuildTypeId) => boolean = (
  state,
  buildTypeId,
) => state.showQueuedBuildsInProject[buildTypeId] ?? false
export const getActualProjectId: (arg0: State, arg1: ProjectId) => ProjectId | null | undefined = (
  state,
  projectId,
) => state.project[projectId]?.data
export const getProjectRequest: (
  arg0: State,
  arg1: ProjectId,
) => Promise<unknown> | null | undefined = (state, projectId) => state.project[projectId]?.request
export const getProjectError: (arg0: State, arg1: ProjectId) => Error | null | undefined = (
  state,
  projectId,
) => state.project[projectId]?.error
export const isBuildLoaded: (arg0: State, arg1: BuildId | null | undefined) => boolean = (
  state,
  id,
) => (id ? getBuild(state, id) != null : false)
const getProjectDescriptionsHash: (arg0: State) => KeyValue<ProjectId, EntityDescription> = state =>
  state.entities.projectDescription
export const getProjectDescription: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => string | null | undefined = (state, id) =>
  id != null ? getProjectDescriptionsHash(state)[id]?.description : null
const getBuildTypeDescriptionsHash: (
  arg0: State,
) => KeyValue<BuildTypeId, EntityDescription> = state => state.entities.buildTypeDescription
export const getBuildTypeDescription: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => string | null | undefined = (state, id) =>
  id != null ? getBuildTypeDescriptionsHash(state)[id]?.description : null
export const getBranchesWithBuildsLoadingReady: (arg0: State, arg1: BuildTypeId) => boolean = (
  state,
  buildTypeId,
) => state.branchesWithBuilds[buildTypeId]?.ready || false

const makeGetBranches = (
  branchPredicate: (branch: NormalizedBranchWithBuilds) => boolean,
): ((state: State, buildTypeId?: BuildTypeId) => ReadonlyArray<StatusKey>) =>
  createSelector(
    (state: State, buildTypeId: BuildTypeId | undefined) =>
      buildTypeId != null ? state.branchesWithBuilds[buildTypeId]?.data ?? emptyArray : emptyArray,
    (state: State) => state.entities.branches,
    (keys, branches) =>
      keys.filter(key => {
        const branch = branches[key]
        return branch != null && branchPredicate(branch)
      }),
  )

const branchHasBuilds = (branch: NormalizedBranchWithBuilds) => branch.builds.build.length > 0

const getDefaultBranch: (state: State, buildTypeId?: BuildTypeId) => ReadonlyArray<StatusKey> =
  makeGetBranches(branch => branch.default === true && branchHasBuilds(branch))
const getNonDefaultBranchesWithBuilds: (
  state: State,
  buildTypeId?: BuildTypeId,
) => ReadonlyArray<StatusKey> = makeGetBranches(
  branch => branch.default !== true && branchHasBuilds(branch),
)
const getNonBuiltBranches: (state: State, buildTypeId?: BuildTypeId) => ReadonlyArray<StatusKey> =
  makeGetBranches(branch => !branchHasBuilds(branch))
export const BranchSelectors = {
  default: getDefaultBranch,
  nonDefault: getNonDefaultBranchesWithBuilds,
  nonBuilt: getNonBuiltBranches,
}
export type BranchesToShow = $Keys<typeof BranchSelectors>
export const buildTypeBranchesSectionCollapsedKey: (
  arg0: BuildTypeId,
  arg1: BranchesToShow,
) => string = (buildTypeId, branchesToShow) => `${stringifyId(buildTypeId)}_${branchesToShow}`
export const isBuildTypeBranchesSectionCollapsed: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
  arg2: BranchesToShow,
) => boolean = (state, buildTypeId, branchesToShow) => {
  if (buildTypeId == null || branchesToShow === 'default') {
    return false
  }

  return getIsBlockIncluded(
    state,
    CollapsibleBlocks.COLLAPSED_BRANCHES_SECTION,
    buildTypeBranchesSectionCollapsedKey(buildTypeId, branchesToShow),
  )
}
export const isProjectBuildTypeLineCollapsed: (
  arg0: State,
  arg1: BuildTypeId,
  arg2: boolean,
) => boolean = (state, buildTypeId, defaultCollapsed) =>
  getIsBlockIncluded(
    state,
    CollapsibleBlocks.COLLAPSED_PROJECT_BUILDTYPELINE,
    stringifyId(buildTypeId),
    defaultCollapsed,
  )

export const getIsAllSubtreeCollapsed: (
  arg0: State,
  arg1: ProjectsTreeType | null | undefined,
) => boolean = createSelector(
  (_: State, tree: ProjectsTreeType | null | undefined) => tree,
  (state: State) =>
    state.blocks[CollapsibleBlocks.COLLAPSED_PROJECT_BUILDTYPELINE] ?? getEmptyHash(),
  (state: State) => state.blocks[CollapsibleBlocks.COLLAPSED_SUBPROJECT] ?? getEmptyHash(),
  (subprojectTree, buildTypeLineBlocks, subprojectBlocks) => {
    if (!subprojectTree) {
      return false
    }

    for (let i = 0; i < subprojectTree.data.length; i++) {
      const item = subprojectTree.data[i]

      if (
        (item.itemType === 'buildType' && buildTypeLineBlocks[item.id] !== false) ||
        (item.itemType === 'project' && subprojectBlocks[item.id] !== false)
      ) {
        return false
      }
    }

    return true
  },
)
export const getIsSubprojectCollapsed: (arg0: State, arg1: ProjectId, arg2?: boolean) => boolean = (
  state,
  projectId,
  defaultCollapsed = true,
) => getIsBlockIncluded(state, CollapsibleBlocks.COLLAPSED_SUBPROJECT, projectId, defaultCollapsed)
export const getBranch: (
  arg0: State,
  arg1: StatusKey,
) => NormalizedBranchWithBuilds | null | undefined = (state, key) => state.entities.branches[key]
export const getHasFavoriteBuilds: (arg0: State) => boolean = state => {
  const builds = state.hasBuilds[STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER]
  return (builds?.ready && builds.data) || false
}
export const getBuildArtifactsSizes: (
  arg0: State,
  arg1: BuildId,
) => BuildArtifactsSizeType | null | undefined = (state, buildId) => state.artifactSizes[buildId]
export const getServerInfo: (arg0: State) => ServerInfo | null | undefined = state =>
  state.serverInfo
export const getHtml: (
  arg0: State,
  arg1: string | null | undefined,
) => string | null | undefined = (state, path) => (path != null ? state.html[path] : null)
export const getTabUrl = (
  {projectId, buildTypeId, buildId}: ActiveEntityProps,
  tab: TabId,
): string | undefined => {
  if (projectId == null && buildTypeId == null && buildId == null) {
    return undefined
  }

  const currentSearch = new URLSearchParams(location.search)
  const tabValue = String(tab)

  if (buildId != null) {
    currentSearch.set('buildTab', tabValue)
  } else if (buildTypeId) {
    currentSearch.set('buildTypeTab', tabValue)
  } else {
    currentSearch.set('projectTab', tabValue)
  }

  return `${getOverviewHref({
    projectId,
    buildTypeId,
    buildId,
  })}?${currentSearch.toString()}`
}
const getChildBuildConfigurations: (
  arg0: State,
  arg1: ProjectId,
  arg2: boolean | null | undefined,
) => ReadonlyArray<BuildTypeId> = (state, projectId, onlyFavorites) =>
  (onlyFavorites === true ? getOverviewProject : getProject)(state, projectId)?.buildTypes
    ?.buildType ?? emptyArray
export const getHasBuildConfigurations: (
  arg0: State,
  arg1: ProjectId,
  arg2: boolean | null | undefined,
) => boolean = (state, projectId, onlyFavorites) =>
  getChildBuildConfigurations(state, projectId, onlyFavorites).length > 0
export const getProjectId = (
  state: State,
  node?: ProjectOrBuildTypeNode | null,
): ProjectId | null | undefined => {
  if (node == null) {
    return null
  }

  switch (node.nodeType) {
    case 'project':
      return node.id

    case 'bt':
      return getBuildType(state, node.id)?.projectId

    default:
      return null
  }
}
export const getBuildsStats: (arg0: State, arg1: string) => ReadonlyArray<BuildStats> = (
  state,
  locator,
) => state.buildsStats[locator]?.data ?? emptyArray
export const getBuildsStatsLoading: (arg0: State, arg1: string) => boolean = (state, locator) =>
  state.buildsStats[locator]?.loading ?? false
export const getBuildsStatsReady: (arg0: State, arg1: string) => boolean = (state, locator) =>
  state.buildsStats[locator]?.ready ?? false
export const getRunningBuildsCount: (arg0: State, arg1: string) => number = (state, locator) =>
  getBuildsStats(state, locator).filter(stat => stat.state === 'running').length

const mergeBuildStats: (
  arg0: BuildStats,
  arg1: KeyValue<BuildId, NormalizedBuildType | null>,
) => BuildStats = (stat, builds) => {
  const build = builds[stat.id]

  if (!build || build.state !== 'running' || stat.state !== 'running') {
    return stat
  }

  const {status = 'UNKNOWN', state, ['running-info']: runningInfo} = build
  return {...stat, status, state, duration: runningInfo?.elapsedSeconds ?? stat.duration}
}

export const getBuildStats: (arg0: State, arg1: BuildStats) => BuildStats = (state, stat) =>
  mergeBuildStats(stat, getBuildsHash(state))
export const makeGetMaxRunningBuildDuration: () => (arg0: State, arg1: string) => number = () =>
  createSelector(getBuildsStats, getBuildsHash, (stats, builds) =>
    Math.max(
      ...stats
        .filter(({state}) => state === 'running')
        .map(stat => mergeBuildStats(stat, builds).duration),
    ),
  )
export const getLastBuildStat: (arg0: State, arg1: string) => BuildStats | null | undefined = (
  state,
  locator,
) => getBuildsStats(state, locator)[0]
export const getNextBuildIdInBuildsStats: (
  arg0: State,
  arg1: string,
  arg2: BuildId | null | undefined,
) => BuildId | null | undefined = (state, locator, buildId) => {
  const stats = getBuildsStats(state, locator)
  const wantedBuildIndex = stats.findIndex(stat => stat.id === buildId)
  // https://github.com/facebook/flow/issues/8187
  // flowlint-next-line unnecessary-optional-chain:off
  return wantedBuildIndex !== -1 ? stats[wantedBuildIndex - 1]?.id : null
}
export const getPreviousBuildIdInBuildsStats: (
  arg0: State,
  arg1: string,
  arg2: BuildId | null | undefined,
) => BuildId | null | undefined = (state, locator, buildId) => {
  const stats = getBuildsStats(state, locator)
  const wantedBuildIndex = stats.findIndex(stat => stat.id === buildId)
  // https://github.com/facebook/flow/issues/8187
  // flowlint-next-line unnecessary-optional-chain:off
  return wantedBuildIndex !== -1 ? stats[wantedBuildIndex + 1]?.id : null
}
export const makeGetProjectBuildTypes: () => (
  arg0: State,
  arg1: ProjectId,
) => ReadonlyArray<BuildTypeType> = () =>
  createSelector(getProject, getBuildTypesHash, (project, buildTypes) => {
    const buildTypeIDs = project?.buildTypes?.buildType || emptyArray

    if (buildTypeIDs.length === 0) {
      return emptyArray
    }

    return buildTypeIDs.map(buildTypeId => buildTypes[buildTypeId]).filter(Boolean)
  })
export const getIsReadOnly: (arg0: State, arg1: ProjectId | null | undefined) => boolean = (
  state,
  projectId,
) => getProject(state, projectId)?.readOnlyUI?.value === true
export const getIsSakuraSelected: (arg0: State) => boolean = state => {
  switch (internalProps['teamcity.ui.experimental']) {
    case SakuraUIEnabledEnum.ENABLE_ALL:
      return true

    case SakuraUIEnabledEnum.ENABLE_DEFAULT:
      return getBooleanUserProperty(state, UserProperties.USE_EXPERIMENTAL_OVERVIEW, true)

    case SakuraUIEnabledEnum.DISABLE_DEFAULT:
    default:
      return getBooleanUserProperty(state, UserProperties.USE_EXPERIMENTAL_OVERVIEW)
  }
}
const MAX_QUEUED_COUNT_FOR_AUTO_EXPAND = 3
export const shouldAutoExpandQueued = (state: State, key: StatusKey): boolean =>
  !getShowQueuedBuildsInBuildsList(state) &&
  (getStatusByKey(state, key)?.queued ?? 0) <= MAX_QUEUED_COUNT_FOR_AUTO_EXPAND
export const getAutoExpandQueuedBuilds: (arg0: State, arg1: StatusKey) => boolean = (
  state,
  statusKey,
) => state.queuedToggler.autoExpand[statusKey] === true
export const getHasTriggeredByMeBuilds: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => boolean = (state, buildTypeId) =>
  buildTypeId != null && state.queuedToggler.hasTriggeredByMeBuilds[buildTypeId] === true
export const getAreAgentsUnlimited = (state: State): boolean | null | undefined =>
  state?.licensingData?.data?.agentsLeft === -1
export const getRemainingAgentsNumber = (state: State): number | null | undefined =>
  state.licensingData?.data?.agentsLeft
export const getIsAgentLeft = (state: State): boolean | null | undefined => {
  const areUnlimited = getAreAgentsUnlimited(state)
  const remainingNumber = getRemainingAgentsNumber(state)

  return areUnlimited || (remainingNumber != null && remainingNumber > 0)
}

export const getIsAgentTypeManageable = (state: State, agentTypeId: AgentTypeId): boolean =>
  isCloudImageManageable(
    getCloudImage(state, agentTypeId) ?? getSingleCloudImage(state, agentTypeId),
  )

const tabsBlackList = {
  bt: [
    ...(internalProps['teamcity.ui.tabs.buildType.chain'] !== false
      ? []
      : [MainUIBuildTypeTabNamesEnum.buildTypeChains]),
    ...(typeof internalProps['teamcity.ui.tabs.linkToMainUI.buildType'] === 'string'
      ? internalProps['teamcity.ui.tabs.linkToMainUI.buildType'].split(',')
      : []),
  ] as Array<TabId | string>,
  project: [
    ...(typeof internalProps['teamcity.ui.tabs.linkToMainUI.project'] === 'string'
      ? internalProps['teamcity.ui.tabs.linkToMainUI.project'].split(',')
      : []),
  ] as Array<string>,
  build: [
    ...(typeof internalProps['teamcity.ui.tabs.linkToMainUI.build'] === 'string'
      ? internalProps['teamcity.ui.tabs.linkToMainUI.build'].split(',')
      : []),
  ] as Array<string>,
}
export const tabIsBlackListed = (
  tabId: TabId | null | undefined,
  nodeType: NodeType | null | undefined,
): boolean => {
  if (!tabId || !nodeType) {
    return false
  }

  const nodeTypeBlackList = tabsBlackList[nodeType] ?? emptyArray
  return nodeTypeBlackList.includes(tabId)
}

const availibleTabNamesOnlyForQueuedBuild = [
  BuildPageTabNamesEnum.queuedBuildCompatibilityTab,
  BuildPageTabNamesEnum.queuedBuildOverviewTab,
]

export const tabExists: (
  arg0: State,
  arg1: TabParamsKey,
  arg2: TabId | null | undefined,
) => boolean = (state, tabParamsKey, tabId) => {
  const allowedTabs = getTabs(state, tabParamsKey)
  return allowedTabs.some(item => item.id === tabId)
}
export const getDefaultProjectTab: (arg0: State, arg1: ProjectId) => TabId = (state, projectId) => {
  const parameters = state.entities.projectParameters[projectId]?.parameters
  const tabParamsKey = getTabParamsKey({
    projectId,
  })
  const preferredTab = getPropertyFromList(BuildTypeProperties.DEFAULT_PROJECT_TAB, parameters)

  if (preferredTab && tabExists(state, tabParamsKey, preferredTab)) {
    return preferredTab
  }

  return ProjectPageTabNamesEnum.OVERVIEW
}

const getDefaultBuildTypeTab: (arg0: State, arg1: BuildTypeId) => TabId = (state, buildTypeId) => {
  const parameters = state.entities.buildTypeParameters[buildTypeId]?.parameters
  const tabParamsKey = getTabParamsKey({
    buildTypeId,
  })
  const preferredTab = getPropertyFromList(BuildTypeProperties.DEFAULT_BUILDTYPE_TAB, parameters)

  if (preferredTab && tabExists(state, tabParamsKey, preferredTab)) {
    return preferredTab
  }

  return BuildTypePageTabNamesEnum.OVERVIEW
}

const getIsAvailableBuildTab: (
  state: State,
  buildId: BuildId | undefined | null,
  tab: TabId | null | undefined,
) => boolean = (state, buildId, tab) => {
  const queued = getBuild(state, buildId)?.state === 'queued'

  if (buildId == null || tab == null) {
    return false
  }

  return queued ? true : !availibleTabNamesOnlyForQueuedBuild.includes(tab)
}

export const getCurrentBuildTypeTab = (state: State): TabId | null => state.buildTypeTab
export const getCurrentProjectPageTab = (state: State): TabId | null => state.projectPage.tab
export const getCurrentBuildTab = (state: State): TabId | null => state.buildTab
export const projectTabPredefined: (arg0: TabId | null | undefined) => boolean = tab =>
  tab != null && Object.values(ProjectPageTabNamesEnum).includes(tab)
export const buildTypeTabPredefined: (arg0: TabId | null | undefined) => boolean = tab =>
  tab != null && Object.values(BuildTypePageTabNamesEnum).includes(tab)
export const buildTabPredefined: (arg0: TabId | null | undefined) => boolean = tab =>
  tab != null && Object.values(BuildPageTabNamesEnum).includes(tab)

interface CalcNextTabParams {
  tab: TabId | null | undefined
  buildId?: BuildId | null | undefined
  buildTypeId?: BuildTypeId
  projectId?: ProjectId
}
export const calcNextTab = (
  state: State,
  {tab, buildId, buildTypeId, projectId}: CalcNextTabParams,
): TabId => {
  const tabParamsKey = getTabParamsKey({
    buildId,
    buildTypeId,
    projectId,
  })
  let defaultTab = BuildPageTabNamesEnum.OVERVIEW
  let tabPredefined = buildTabPredefined(tab)
  let availableTab = getIsAvailableBuildTab(state, buildId, tab)
  let tabInBlackList = tabIsBlackListed(tab, 'build')

  if (buildTypeId != null) {
    defaultTab = getDefaultBuildTypeTab(state, buildTypeId)
    tabPredefined = buildTypeTabPredefined(tab)
    availableTab = true
    tabInBlackList = tabIsBlackListed(tab, 'bt')
  }

  if (projectId != null) {
    defaultTab = getDefaultProjectTab(state, projectId)
    tabPredefined = projectTabPredefined(tab)
    availableTab = true
    tabInBlackList = tabIsBlackListed(tab, 'project')
  }

  return tab &&
    availableTab &&
    !tabInBlackList &&
    (tabExists(state, tabParamsKey, tab) || tabPredefined)
    ? tab
    : defaultTab
}
export const getChangesHash: (state: State) => KeyValue<ChangeId, NormalizedChangeType> = state =>
  state.entities.changes
export const getChange: (
  state: State,
  changeId: ChangeId | null | undefined,
) => NormalizedChangeType | null | undefined = (state, changeId) =>
  changeId != null ? getChangesHash(state)[changeId] : null
const getBuildFromNormalized = (data?: NormalizedBuild) => data?.entities.builds?.[data?.result]
export const getFirstBuildIdWithSameChanges = (data?: NormalizedBuild) =>
  data?.entities.buildsFirstBuildWithSameChanges?.[data?.result]?.id
export const getBuildChangesCount = (data?: NormalizedBuild) =>
  getBuildFromNormalized(data)?.changes?.change?.length ?? 0
export const getArtifactChangesCount = (data?: NormalizedBuild) =>
  getBuildFromNormalized(data)?.artifactDependencyChanges?.count ?? 0
export const getBuildChangesIdsByBuildId: (
  state: State,
  buildId: BuildId,
) => ReadonlyArray<ChangeId> | null | undefined = (state, buildId) =>
  state.changesDropdown.buildChangesByBuildId[buildId]
export const getBuildChanges: (
  state: State,
  buildId: BuildId,
) => ReadonlyArray<NormalizedChangeType> = createSelector(
  [getBuildChangesIdsByBuildId, getChangesHash],
  (
    ids: ReadonlyArray<ChangeId> | null | undefined,
    changes: KeyValue<ChangeId, NormalizedChangeType>,
  ) => (ids != null ? ids.map((id: ChangeId) => changes[id]).filter(Boolean) : emptyArray),
)
export const getVcsRoots: (state: State) => KeyValue<VcsRootId, VcsRootType> = state =>
  state.entities.vcsRoots
export const getVcsRootInstances: (
  state: State,
) => KeyValue<VcsRootInstanceId, NormalizedVcsRootInstanceType> = state =>
  state.entities.vcsRootInstances
export const getVcsRoot: (
  state: State,
  vcsRootId: VcsRootId | null | undefined,
) => VcsRootType | null | undefined = (state, vcsRootId) =>
  vcsRootId != null ? getVcsRoots(state)[vcsRootId] : null
export const getVcsRootInstance: (
  state: State,
  vcsRootInstanceId: VcsRootInstanceId | null | undefined,
) => NormalizedVcsRootInstanceType | null | undefined = (state, vcsRootInstanceId) =>
  vcsRootInstanceId != null ? state.entities.vcsRootInstances[vcsRootInstanceId] : null
