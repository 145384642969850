import {useSelector} from 'react-redux'

import {getIsSakuraSelected} from '../../selectors'
import {internalProps} from '../../types/BS_types'
import {isEmbedded} from '../../utils/isEmbedded'

export const useSakuraReleaseBanner = () => {
  const sakuraSelected = useSelector(getIsSakuraSelected)
  return (
    internalProps['teamcity.ui.sakura.releaseBanner.enabled'] === true &&
    !isEmbedded &&
    !sakuraSelected
  )
}
